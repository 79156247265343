<script setup>
import { computed, ref } from "vue";
import { environment } from "../env/environment";
import GeolocationStore from "@/store/geolocation.store";
import { useGeolocation } from "../composables/useGeolocation";
import UtilStore from "@/store/utils.store";

defineProps({
  isPlaceReservationActive: {
    type: Boolean,
    default: false,
  },
});

const geolocationData = computed(() => GeolocationStore.state.dataCopy);

const menuCountryId = computed(() => UtilStore.state.menuCountryId);
const countryId = computed(() => {
  if (
    menuCountryId.value?.type === "colonyName" ||
    menuCountryId.value?.type === "cityName"
  ) {
    return menuCountryId.value.country === "mx"
      ? 142
      : menuCountryId.value.country;
  }

  return menuCountryId.value
    ? menuCountryId.value?.id === "mx"
      ? 142
      : menuCountryId.value
    : geolocationData.value.country_id;
});

const { generateGeolocationUrlByCountry } = useGeolocation();
const url = computed(() =>
  generateGeolocationUrlByCountry(geolocationData.value)
);

const links = computed(() => [
  { name: "Cadenas", url: "/cadenas-de-restaurantes-bares-y-antros/" },
  { name: "Restaurantes", url: `${url.value}restaurantes/` },
  { name: "Bares", url: `${url.value}bares/` },
  { name: "Antros", url: `${url.value}antros/` },
]);

const currentYear = ref(new Date().getFullYear());
const aboutUs = [
  { name: "Términos y condiciones", url: "/terminos-y-condiciones/" },
  { name: "Aviso de privacidad", url: "/aviso-de-privacidad/" },
  { name: "Blog", url: "/blog" },
];

const socialLinks = [
  {
    url: "https://www.facebook.com/reservandonos/",
    icon: "fab fa-facebook-f",
  },
  {
    url: "https://www.instagram.com/reservandonos",
    icon: "fab fa-instagram",
  },
  {
    url: "https://youtube.com/@reservandonos",
    icon: "fab fa-youtube",
  },
  {
    url: "https://www.tiktok.com/@reservandonos",
    icon: "fab fa-tiktok",
  },
];

const topLocation = [
  {
    name: "Ciudad de México",
    url: "/mx/ciudad-de-mexico/",
  },
  {
    name: "Cancún",
    url: "/mx/quintana-roo/cancun/",
  },
  {
    name: "Puebla",
    url: "/mx/puebla/",
  },
  {
    name: "Guadalajara",
    url: "/mx/jalisco/guadalajara/",
  },
  {
    name: "Monterrey",
    url: "/mx/nuevo-leon/monterrey/",
  },
  {
    name: "Querétaro",
    url: "/mx/queretaro/",
  },
  {
    name: "Puerto Vallarta",
    url: "/mx/jalisco/puerto-vallarta/",
  },
];

const topColonies = [
  {
    name: "Polanco",
    url: "/mx/ciudad-de-mexico/polanco/",
  },
  {
    name: "Condesa/Roma",
    url: "/mx/ciudad-de-mexico/condesa-roma/",
  },
  {
    name: "San Ángel",
    url: "/mx/ciudad-de-mexico/san-angel/",
  },
];

const experiencies = [
  {
    name: "Paseos en Bus",
    url: "/experiencias/",
  },
  {
    name: "Visitas guiada",
    url: "/experiencias/",
  },
];
</script>

<template>
  <div
    class="footerfix"
    :class="{ 'place-reservation-active': isPlaceReservationActive }"
  >
    <section id="contact">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-4 col-md-4">
            <div>
              <span class="title-footer">Reservándonos</span>
              <ul class="alt text-footer mt-2">
                <li v-for="(link, index) in links" :key="index" class="py-1">
                  <router-link class="text-footer" :to="link.url">{{
                    link.name
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div v-if="countryId === 142" class="mt-4">
              <span class="title-footer">Nosotros</span>
              <ul class="alt text-footer mt-2">
                <li v-for="(item, index) in aboutUs" :key="index" class="py-1">
                  <a class="text-footer" :href="item.url">{{ item.name }}</a>
                </li>
              </ul>
            </div>

            <div v-if="countryId === 142" class="mt-4">
              <span class="title-footer">Actividades en México</span>
              <ul class="alt text-footer mt-2">
                <li
                  v-for="(item, index) in experiencies"
                  :key="index"
                  class="py-1"
                >
                  <router-link :to="item.url" class="text-footer">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 custom-us">
            <div v-if="countryId !== 142">
              <span class="title-footer">Nosotros</span>
              <ul class="alt text-footer mt-2">
                <li v-for="(item, index) in aboutUs" :key="index" class="py-1">
                  <a class="text-footer" :href="item.url">{{ item.name }}</a>
                </li>
              </ul>
            </div>

            <div v-if="countryId === 142">
              <span class="title-footer">Lugares más visitados en México</span>
              <ul class="alt text-footer mt-2">
                <li
                  v-for="(item, index) in topLocation"
                  :key="index"
                  class="py-1"
                >
                  <router-link :to="item.url" class="text-footer">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>

            <div v-if="countryId === 142" class="mt-4">
              <span class="title-footer">Colonias en tendencias</span>
              <ul class="alt text-footer mt-2">
                <li
                  v-for="(item, index) in topColonies"
                  :key="index"
                  class="py-1"
                >
                  <router-link :to="item.url" class="text-footer">
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-4">
            <div class="form text-lg-right text-md-right">
              <span class="title-footer title-social-link">
                <a href="https://partners.reservandonos.com/">
                  <h3>¿Tienes un lugar? Súbelo ahora</h3>
                </a>
              </span>

              <span class="title-footer">Siguenos en:</span>
              <div class="social-links">
                <a
                  v-for="(item, index) in socialLinks"
                  :key="index"
                  :href="item.url"
                  class="facebook"
                  :aria-label="item.icon"
                >
                  <i :class="[item.icon]"></i>
                </a>
              </div>
              <div class="">
                <a
                  href="https://play.google.com/store/apps/details?id=user.reservandonos.com"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    width="140"
                    height="45"
                    class="footer-app-store padding-3 mb-1"
                    :src="`${environment.imageKeyCDN}/assets/images/icons/play-store.svg`"
                    alt="play-store"
                  />
                </a>
                <br />
                <a
                  href="https://apps.apple.com/us/app/reserv%C3%A1ndonos/id1424467304"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    width="140"
                    height="37"
                    class="footer-app-store"
                    :src="`${environment.imageKeyCDN}/assets/images/icons/app-store.svg`"
                    alt="app-store"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer id="footer">
      <div class="container">
        <div class="row container-footer">
          <div class="col-lg-4 col-md-4 mt-1">
            <span class="span-copyright">
              <img
                width="22"
                height="23"
                loading="lazy"
                :src="`${environment.imageKeyCDN}/assets/images/website-logo-icon/isotipo.svg`"
                alt="logo"
              />
              ©<span id="currentYear">{{ currentYear }}</span> reservandonos.com
            </span>
          </div>
          <div class="col-lg-5 col-md-5 mt-1">
            <span>
              <i class="fa fa-map-marker-alt"></i>
              Hegel 713, Polanco V Secc, Miguel Hidalgo, 11560 Ciudad de México,
              CDMX
            </span>
          </div>
          <div class="col-lg-3 col-md-3 mt-1">
            <span
              class="d-lg-flex d-md-flex justify-content-lg-end justify-content-md-end"
            >
              <img
                width="84"
                height="27"
                loading="lazy"
                :src="`${environment.imageKeyCDN}/assets/images/footer/mexico.svg`"
                alt="mexico"
              />
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<style lang="css">
#contact {
  padding: 35px 0;
  overflow: hidden;
  background-color: #f2f2f5 !important;
}

#contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;

  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--sitecolor);
}

#contact .contact-about p {
  font-size: 14px;
  line-height: 24px;

  color: #888;
}

#contact .social-links {
  padding-bottom: 20px;
}

#contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: var(--sitecolor);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid var(--sitecolor);
}

#contact .social-links a:hover {
  background: var(--sitecolor);
  color: #fff;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 20px;
  color: var(--sitecolor);
  float: left;
  line-height: 1;
}

#contact .info p {
  padding: 0 0 10px 30px;
  font-size: 14px;
}

#contact .form #sendmessage {
  color: var(--sitecolor);
  border: 1px solid var(--sitecolor);
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

#contact .form button[type="submit"] {
  background: linear-gradient(45deg, #1de099, var(--sitecolor));
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
}

#contact .form button[type="submit"]:hover {
  cursor: pointer;
}

#contact_number,
#signup-contact {
  width: 100%;
  height: auto;
  padding-left: 90px !important;

  outline: none;
  font-size: 1em;
  color: #000;
  padding: 1em 0.5em;
  margin: 0;
  border: 1px solid #7a73735c;
  margin-top: 15px;
  font-family: "SourceSansPro";
}

#contact .nav-menu > li {
  float: none !important;
}

#contact .nav-menu .nav-item,
#contact .nav-menu .nav-item > a {
  margin: 0;
  padding: 0;
}

#contact .nav-menu .nav-item > a {
  font-size: 16px;
}

#contact .social-links a {
  color: #363636;
  border: 1px solid #ffffff;
}

#contact .social-links a:hover {
  background: #363636;
  color: #ffffff;
}

.footer-app-store {
  width: 140px;
}

.title-footer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #3a3a3c;
}

.title-footer > a > h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.title-social-link {
  color: #82cde5 !important;
}

.text-footer {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #3a3a3c;
}

#footer {
  background: #f2f2f5;
  padding: 0;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #000000;
}

#footer .credits {
  font-size: 13px;
  color: #888;
}

#footer .footer-links a {
  color: #666;
  padding-left: 15px;
}

#footer .footer-links a:first-child {
  padding-left: 0;
}

#footer .footer-links a:hover {
  color: var(--sitecolor);
}

.container-footer > div > span {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #000000;
}

.container-footer > div > span > i {
  color: #6b7588;
}

.span-copyright > img {
  width: 22px;
  margin-right: 5px;
}

.footerfix {
  position: inherit;
  width: 100%;
}
</style>
