import { createRouter, createWebHistory } from "vue-router";
import locationRoutes from "./location.routes";
import routes from "./list.routes";
import { middlwareRequireAuth } from "./middleware.routes";

const router = createRouter({
  history: createWebHistory(),
  routes: [...routes, ...locationRoutes],
});

middlwareRequireAuth(router);

export default router;
