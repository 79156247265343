import Swal from "sweetalert2";

export const errorAlert = (text, title = "¡Error!") => {
  Swal.fire({
    icon: "error",
    title,
    text,
  });
};

export const informationAlert = (text, title = "¡Atención!") => {
  Swal.fire({
    icon: "info",
    title,
    text,
  });
};

export const confirmAlert = async ({
  text,
  title = "¡Atención!",
  confirmButtonText = "Confirmar",
}) => {
  return await Swal.fire({
    icon: "question",
    title,
    text,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText: "Cancelar",
  });
};

export const toastAlert = (text, title = "¡Atención!", icon = "success") => {
  Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  }).fire({
    icon,
    title,
    text,
  });
};

export const sharedAlert = (isExperience = false) => {
  const currentUrl = window.location.href;
  Swal.fire({
    html: `
            <p class="profile-share-modal-content-title">Comparte con tus amigos y familia ${
              !isExperience ? "este lugar" : "esta experiencia"
            }</p>
            <div class="profile-share-modal-content-options-container">
                <div class="profile-share-modal-content-option" id="get_link">
                    <img loading="lazy" src="https://reservandonos.com/blog/wp-content/themes/reservandonos/images/botones/copy.png"
                        class="profile-share-modal-content-option-img" alt="Copiar" />
                    <p class="profile-share-modal-content-option-text">Copiar Enlace</p>
                </div>
                <a class="profile-share-modal-content-option-link" href="https://api.whatsapp.com/send?text=${currentUrl}&app_absent=1"
                    target="_blank">
                    <div class="profile-share-modal-content-option">
                        <img loading="lazy" src="https://reservandonos.com/blog/wp-content/themes/reservandonos/images/botones/whatsapp.png"
                            class="profile-share-modal-content-option-img" alt="Whatsapp" />
                        <p class="profile-share-modal-content-option-text">Whatsapp</p>
                    </div>
                </a>
                <a class="profile-share-modal-content-option-link" href="http://www.facebook.com/sharer.php?u=${currentUrl}" target="_blank">
                    <div class="profile-share-modal-content-option">
                        <img loading="lazy" src="https://reservandonos.com/blog/wp-content/themes/reservandonos/images/botones/facebook.png"
                            class="profile-share-modal-content-option-img" alt="Facebook" />
                        <p class="profile-share-modal-content-option-text">Facebook</p>
                    </div>
                </a>
                <a class="profile-share-modal-content-option-link"
                    href="mailto:?body=${currentUrl}">
                    <div class="profile-share-modal-content-option">
                        <img loading="lazy" src="https://reservandonos.com/blog/wp-content/themes/reservandonos/images/botones/email.png"
                            class="profile-share-modal-content-option-img" alt="Email" />
                        <p class="profile-share-modal-content-option-text">Email</p>
                    </div>
                </a>
            </div>
        `,
    showConfirmButton: false,
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    title: `
            <div class="profile-share-modal-header-container">
                <img loading="lazy" src="https://reservandonos.com/blog/wp-content/themes/reservandonos/images/logo.png" class="profile-share-modal-header-icon" alt="Reservaciones Exclusivas | Reservándonos" itemprop="logo">
                <p class="profile-share-modal-header-title">COMPARTIR ${
                  !isExperience ? "LUGAR" : "EXPERIENCIA"
                }</p>
            </div>
        `,
    customClass: {
      popup: "profile-share-modal",
      title: "profile-share-modal-header",
      htmlContainer: "profile-share-modal-content-container",
    },
  });
};
