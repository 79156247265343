import { useUtil } from "@/composables/useUtil";
import AuthStore from "@/store/auth.store";
import UtilStore from "@/store/utils.store";
import { useShoppingStore } from "../store/shoppingStore";
const { changeCanonicalUrl, removeMetaSeo } = useUtil();

export const middlwareRequireAuth = (router) => {
  router.beforeEach((to, from, next) => {
    UtilStore.commit("setPreviousRoute", from);
    removeMetaSeo();
    UtilStore.commit("setMenuCountryId", null);

    if (to.name == "checkout") {
      const myShoppingCart = useShoppingStore();
      if (myShoppingCart.items.length == 0) next({ name: "stores" });
    }

    if (to.meta.requiresAuth) {
      // Comprueba si el usuario tiene una sesión iniciada
      if (AuthStore.state.user?.id) {
        next();
      } else {
        // El usuario no está autenticado, redirige a la página de inicio de sesión u otra página de autenticación
        next("/");
      }
    } else {
      // Si la ruta no requiere autenticación, permite que la ruta se cargue sin verificar la sesión
      next();
    }
  });

  router.afterEach(() => {
    changeCanonicalUrl();
  });
};
