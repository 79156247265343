export default [
  {
    path: "/:countryCode",
    name: "country-code",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/slug/index.vue"),
  },
  {
    path: "/:countryCode/:slug",
    name: "country-code-slug",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/slug/index.vue"),
  },
  {
    path: "/:countryCode/:state/:slug",
    name: "country-code-state-slug",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/slug/index.vue"),
  },
  {
    path: "/:countryCode/:state/:cityOrColony/:slug",
    name: "country-code-state-city-slug",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/slug/index.vue"),
  },
];
