import { createStore } from "vuex";
import { changeToData, fetchGet } from "./helper";
import UtilStore from "./utils.store";
import { useUtil } from "../composables/useUtil";
const { defaultViewIndexs } = useUtil();

const FilterStore = createStore({
  state: {
    items: {},
    isOpenModal: false,
    isUnselectAll: false,
    itemsCopy: [],
    selectedsCopy: [],
    selecteds: [],
    selectedsAll: [],
    viewIndexs: defaultViewIndexs,
    propertiesBySlug: {},
    filterType: {
      country_id: null,
      state_id: null,
      city_id: null,
      colony_id: null,
      category_id: null,
      food_type_id: null,
      cuisine_id: null,
      latitude: null,
      longitude: null,
    },
    defaultItems: {
      location: {
        country: null,
        state: null,
        city: null,
        colony: null,
        latitude: null,
        longitude: null,
      },
      category: null,
      food_type: null,
      cuisine: null,
    },
    defaultItemsCopy: {
      location: {
        country: null,
        state: null,
        city: null,
        colony: null,
        latitude: null,
        longitude: null,
      },
      category: null,
      food_type: null,
      cuisine: null,
    },
    isLoadingGetFilter: true,
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    updateItem(state, payload) {
      const index = state.items.findIndex(
        (item) => item.class === payload[0].class
      );
      if (index >= 0) {
        state.itemsCopy[index] = state.items[index];
        state.items[index] = payload[0];
      }
    },
    /* CAMBIO LA COPIA SELECCIONADO */
    changeSelectedsCopy(state, payload) {
      /* BUSCO EN LA COPIA SI ESTÁ YA SE ENCUENTRA EL ITEM AGREGADO POR MEDIO DE LA CLASE Y LA ID */
      const index = state.selectedsCopy.findIndex(
        (item) => item.class === payload.class && item.id === payload.id
      );
      const indexSelecteds = state.selecteds.findIndex(
        (item) => item.class === payload.class && item.id === payload.id
      );
      if (indexSelecteds >= 0) {
        payload.value = false;
      }

      if (index >= 0) {
        /* LO SACO DEL ARRAY YA QUE EN TEORÍA AHORA ES FALSO */
        state.selectedsCopy.splice(index, 1);
        return;
      }

      if (payload.class === "price-range" || payload.class === "score") {
        const index = state.selectedsCopy.findIndex(
          (item) => item.class === payload.class
        );
        if (index >= 0) {
          state.selectedsCopy[index] = payload;
          return;
        }
      }

      /* SACAR DEL ARRAY CUANDO SE SELECCIONÓ TODOS */
      if (payload.isFather) {
        state.selectedsAll.push(payload);
        state.selectedsCopy = state.selectedsCopy.filter(
          (item) =>
            !(
              item.class === payload.class &&
              !item.isFather &&
              item.fatherId === payload.id
            )
        );
      } else {
        /* SACAR DEL ARRAY CUANDO SE SELECCIONÓ UNO DE LOS HIJOS */
        state.selectedsCopy = state.selectedsCopy.filter(
          (item) =>
            !(
              item.class === payload.class &&
              item.isFather &&
              item.id === payload.fatherId
            )
        );
      }

      /* LO AGREGO AL ARRAY */
      state.selectedsCopy = [...state.selectedsCopy, payload];
    },
    clearSelectedsCopy(state) {
      state.selectedsCopy = [];
    },

    isUnselectAll(state, payload) {
      state.isUnselectAll = payload;
    },

    resetViewIndexs(state) {
      const { defaultViewIndexs } = useUtil();
      state.viewIndexs = defaultViewIndexs;
    },
    clearSelecteds(state) {
      state.selectedsAll = [];
      state.selecteds = [];
      sessionStorage.setItem("selecteds", []);
    },

    applySelecteds(state) {
      state.selectedsCopy.forEach((item) => {
        const existingItem = state.selecteds.find(
          (selected) => selected.class === item.class && selected.id === item.id
        );

        if (!existingItem) {
          // Si el elemento no existe en selecteds, lo agregamos
          state.selecteds.push(item);
        } else if (!item.value) {
          // Si el elemento existe y su value es false en selectedsCopy, lo eliminamos de selecteds
          const indexToRemove = state.selecteds.findIndex(
            (selected) =>
              selected.class === item.class && selected.id === item.id
          );
          if (indexToRemove !== -1) {
            state.selecteds.splice(indexToRemove, 1);
          }
        } else if (!existingItem.value && item.value) {
          // Si el elemento existe pero su value es false en selectedsCopy y true en selecteds, lo actualizamos
          existingItem.value = true;
        }
      });

      /* sessionStorage.setItem('selecteds', JSON.stringify(state.selecteds)); */

      state.selectedsCopy = [];
    },

    deleteSelectedAll(state, payload) {
      if (state.selectedsAll.length === 0) return;
      state.selectedsAll = state.selectedsAll.filter(
        (item) =>
          !(
            item.class === payload.class &&
            item.isFather &&
            item.id === payload.fatherId
          )
      );
    },

    deleteSelectedClass(state, itemClass) {
      if (state.selecteds.length === 0) return;
      state.selecteds = state.selecteds.filter(
        (item) => item.class !== itemClass
      );
    },

    deleteSelectedClassCopy(state, itemClass) {
      if (state.selectedsCopy.length === 0) return;
      state.selectedsCopy = state.selectedsCopy.filter(
        (item) => item.class !== itemClass
      );
    },

    changeItemCopy(state, itemClass) {
      const index = state.items.findIndex((item) => item.class === itemClass);
      if (index >= 0) {
        state.items[index] = state.itemsCopy[index];
      }
    },
    setIsLoadingViewIndex(state, payload) {
      state.viewIndexs[payload.class].isLoading = payload.value;
    },

    setIsShowMoreViewIndex(state, payload) {
      state.viewIndexs[payload.class].isShowMore = payload.value;
    },
    setPropertiesBySlug(state, payload) {
      state.propertiesBySlug = payload;
      if (payload.data) {
        UtilStore.commit("setColorHeader", payload.data.color);
      }
    },

    setFilterType(state, payload) {
      if (payload) {
        state.filterType = payload;
        return;
      }

      state.filterType = {
        country_id: state.propertiesBySlug.data.country_id,
        state_id: state.propertiesBySlug.data.state_id,
        city_id: state.propertiesBySlug.data.city_id,
        colony_id: state.propertiesBySlug.data.colony_id,
        category_id: state.propertiesBySlug.data.category_id,
        food_type_id: state.propertiesBySlug.data.food_type_id,
        cuisine_id: state.propertiesBySlug.data.cuisine_id,
      };
    },

    setDefaultItems(state) {
      changeToData(state.defaultItems, state.filterType);
    },

    applyChangeGeolocation(
      state,
      {
        id,
        item,
        isState = false,
        isCity = false,
        isColony = false,
        itemClass = "location",
      }
    ) {
      const index = state.selecteds.findIndex((item) => item.isGeolocation);
      if (index >= 0) {
        state.selecteds[index].id = id;
        state.selecteds[index].item = item;
        state.selecteds[index].isState = isState;
        state.selecteds[index].isCity = isCity;
        state.selecteds[index].isColony = isColony;
        return;
      }

      state.selecteds = [
        ...state.selecteds,
        {
          id,
          item,
          isState,
          isCity,
          isColony,
          isGeolocation: true,
          class: itemClass,
          value: true,
        },
      ];
    },

    setDefaultItemsCopy(state) {
      state.defaultItemsCopy = JSON.parse(JSON.stringify(state.defaultItems));
    },

    resetDefaultItems(state) {
      state.defaultItems = { ...state.defaultItemsCopy };
    },

    setSelectedsDefault(state, payload) {
      state.selecteds = payload;
      state.selectedsCopy = payload;
    },

    setSelectedsCopy(state) {
      state.selectedsCopy = state.selecteds;
    },

    setIsLoadingGetFilter(state, payload) {
      state.isLoadingGetFilter = payload;
    },

    setIsOpenModal(state, payload) {
      state.isOpenModal = payload;
    },
  },
  actions: {
    async getQuantityFilterItems({ commit, state }, params = {}) {
      state.isLoadingGetFilter = true;
      const data = await fetchGet("/api/filterPage/quantityFilterItems", {
        mode: "web",
        ...params,
        ...state.filterType,
        country_id: state.defaultItems.location.country,
        longitude: state.defaultItems.location.longitude,
        latitude: state.defaultItems.location.latitude,
      });
      commit("setItems", data.data);
    },

    async getAllFilterItemsByType({ commit, state }, params = {}) {
      const data = await fetchGet("/api/filterPage/allFilterItemsByType", {
        mode: "web",
        ...params,
        country_id: state.defaultItems.location.country,
        longitude: state.defaultItems.location.longitude,
        latitude: state.defaultItems.location.latitude,
      });
      commit("updateItem", data.data);
    },

    async getSlugPage({ commit }, params = {}) {
      const data = await fetchGet("/api/tools/page", { ...params });
      UtilStore.dispatch("setSeoAndSchema", {
        seo: data.data.data.seo,
        schema: data.data.data.schema,
      });
      commit("setPropertiesBySlug", data.data);
      commit("setFilterType");
      commit("setDefaultItems");
      commit("setDefaultItemsCopy");
    },
  },

  getters: {
    isItemSelected: (state) => (itemClass, id) => {
      return state.selecteds.some(
        (item) => item.class === itemClass && item.id === id
      );
    },
  },
});

export default FilterStore;
