<script setup>
import { computed, defineAsyncComponent } from "vue";
import UtilStore from "@/store/utils.store";
import FilterStore from "@/store/filter.store";
import { environment } from "../../env/environment";
import geolocation from "@/components/geolocation/geolocation.vue";

defineProps(["title"]);

const sidepanelSearch = defineAsyncComponent(() =>
  import("@/components/sidepanels/search.vue")
);
const sidepanelMenu = defineAsyncComponent(() =>
  import("@/components/sidepanels/menu.vue")
);
const sidepanelAuth = defineAsyncComponent(() =>
  import("@/components/sidepanels/auth.vue")
);

const isMobile = computed(() => UtilStore.state.isMobile);
const type = computed(() => FilterStore.state.propertiesBySlug?.data?.type);
const backgroundColor = computed(() => UtilStore.state.colorHeader);
const isSearch = computed(() => UtilStore.state.isSearch);
</script>
<template>
  <div class="container-fluid" :class="{ 'mb-5': isMobile }">
    <div
      id="new_header"
      :style="{ backgroundColor: !isMobile ? '#fff' : backgroundColor }"
      :class="{ 'no-search': !isSearch }"
    >
      <div class="container">
        <nav
          class="navbar navbar-expand-lg navbar-light"
          :class="{ 'mt-0': !isSearch }"
        >
          <div class="main-header-container">
            <div class="menu-icon" id="menu_button">
              <sidepanel-menu :isMobile="isMobile" />
            </div>

            <router-link
              class="navbar-brand"
              id="logo"
              :to="type === 'sabores-locales' ? '/sabores-locales' : '/'"
            >
              <img
                v-if="type === 'sabores'"
                loading="lazy"
                :src="`${environment.imageKeyCDN}/assets/images/website-logo-icon/sabores-locales-by-reservandonos.png`"
                alt=" Identificador de Sabores Locales"
                srcset=""
              />

              <svg
                :class="{ sabores: type === 'sabores' }"
                id="custom-svg"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 740.4 113"
                style="enable-background: new 0 0 740.4 113"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <g>
                      <g fill="#000000" stroke="none">
                        <path
                          class="st0"
                          d="M81.3,108.9c-1.3,0-2.6-0.1-3.8-0.4c-3.4-0.7-6.5-2.2-9.7-4.9c-3.2-2.7-8.6-7.3-11.9-9.9
                                       c-0.7-0.5-1.3-1.1-2-1.6L52.2,98c-2,6.6-11.6,9.4-22,6.3c-4.8-1.4-8.9-3.9-11.8-7c-3-3.3-4.2-7-3.2-10.3l9.6-32.5
                                       c0-0.1,0-0.2,0-0.3c0.9-4,2.9-7.4,6-10.1c-2.7-1.4-5-3.4-6.8-5.9C22.6,36,21.6,33.6,21,31c-0.5-2.5-0.6-5.1-0.1-7.6
                                       c0.8-4.6,3.1-8.5,6.8-11.6c2.6-2.2,5.6-3.6,9-4.2c1.2-0.2,2.3-0.3,3.3-0.3c11.5,0,22.4,0,33.9,0c1.1,0,2.3,0.1,3.4,0.2
                                       c3.4,0.1,20,1.3,28.5,18.1c0.4,0.7,0.7,1.4,1,2c0.7,1.6,1.3,3.4,1.9,5.1c0.6,1.8,1,3.7,1.2,6.1c0.3,3.1,0.3,6.2-0.2,9.2
                                       c-0.4,2.5-1.1,5-2,7.4c-2,5.2-5.1,9.6-9.2,13.3c-1.9,1.7-3.6,3.3-6,4.6c0.2,0.2,2.6,2.3,2.7,2.5c2.2,2.2,3.7,4.9,4.6,7.9
                                       c0.9,2.8,1.1,5.7,0.6,8.6c0,0.3-0.1,0.6-0.2,1c-0.8,3.5-2.3,6.6-4.7,9.2c-2.4,2.7-5.3,4.5-8.7,5.6
                                       C85.2,108.6,83.3,108.9,81.3,108.9z M52,85.6l2.2,1.8c1.4,1.1,2.8,2.3,4.2,3.4c3.2,2.6,8.7,7.2,11.9,9.9c2.7,2.3,5.2,3.6,8,4.1
                                       c2.6,0.5,5.2,0.4,7.7-0.4c2.7-0.8,5.1-2.4,7.1-4.5c1.9-2.1,3.1-4.5,3.8-7.4c0-0.2,0.1-0.5,0.1-0.7c0.4-2.4,0.3-4.7-0.5-6.9
                                       c-0.7-2.5-2-4.6-3.7-6.4c-0.3-0.3-0.5-0.5-0.8-0.8c-0.9-0.9-3.7-3.3-3.7-3.3l-2.3-1.7l2-1.4c0.2-0.1,0.3-0.2,0.5-0.2
                                       c3-1.4,5.5-3,7.8-5c3.7-3.3,6.4-7.3,8.2-11.9c0.8-2.1,1.4-4.4,1.8-6.6c0.4-2.7,0.5-5.5,0.2-8.3v0c-0.2-2.1-0.6-3.8-1.1-5.3
                                       c-0.6-1.7-1.2-3.4-1.9-4.9c-0.2-0.5-0.4-1.1-0.7-1.5l-0.1-0.2c-7.6-15.1-22.5-16.1-25.3-16.1l-0.1,0c-1.1-0.1-2.1-0.1-3.2-0.1
                                       c-11.5,0-22.4,0-33.9,0c-0.8,0-1.7,0.1-2.7,0.2c-2.7,0.5-5.1,1.6-7.2,3.3c-3,2.5-4.8,5.6-5.5,9.4c-0.4,2.1-0.3,4.1,0.1,6.2
                                       c0.4,2.1,1.3,4,2.5,5.7c2,2.8,4.6,4.7,7.9,5.9l3,1.1c0,0-2,1.4-3.2,2.5c-3,3-5.4,5.6-6.4,9.7l0,0.2l-8.4,28.3h0L18.9,88
                                       c-0.6,2,0.2,4.4,2.4,6.8c2.4,2.6,5.9,4.7,10.1,5.9c8.1,2.4,16,0.7,17.3-3.8L52,85.6z"
                        />
                        <path
                          class="st0"
                          d="M81.3,109.3c-1.3,0-2.6-0.1-3.9-0.4c-3.5-0.7-6.6-2.3-9.9-5l-0.6-0.5c-3.3-2.8-8.3-6.9-11.3-9.4l-1.5-1.2
                                       l-1.6,5.3c-1,3.4-3.9,5.9-8.1,7.1c-4.2,1.2-9.3,1-14.4-0.5c-4.8-1.4-9.1-4-11.9-7.1c-3.1-3.5-4.3-7.3-3.3-10.8l9.6-32.5
                                       c0-0.1,0-0.2,0.1-0.3c0.9-3.9,2.8-7.3,5.7-10c-2.5-1.4-4.7-3.4-6.4-5.8c-1.6-2.2-2.7-4.6-3.2-7.3c-0.6-2.6-0.6-5.2-0.1-7.8
                                       c0.9-4.7,3.2-8.7,6.9-11.8c2.7-2.2,5.8-3.7,9.2-4.3C37.8,7,38.9,6.9,40,6.9c10.7,0,21.4,0,33.9,0c1.1,0,2.3,0.1,3.5,0.2
                                       c3.3,0.1,20.2,1.2,28.9,18.3c0.4,0.7,0.8,1.4,1,2.1c0.7,1.6,1.3,3.4,2,5.2c0.6,1.8,1,3.8,1.3,6.2c0.3,3.2,0.3,6.3-0.2,9.4
                                       c-0.4,2.5-1.1,5.1-2,7.5c-2,5.2-5.2,9.7-9.4,13.4l-0.4,0.4c-1.7,1.5-3.1,2.8-5.1,4c0.1,0.1,0.3,0.3,0.5,0.4
                                       c1,0.9,1.7,1.6,1.9,1.7c2.2,2.3,3.8,5,4.7,8c0.9,2.8,1.2,5.8,0.6,8.8c0,0.2-0.1,0.6-0.2,1c-0.8,3.6-2.4,6.7-4.8,9.3
                                       c-2.5,2.7-5.5,4.6-8.9,5.7C85.3,109,83.3,109.3,81.3,109.3z M53.7,91.3l2.5,2c3,2.5,8,6.7,11.3,9.4l0.6,0.5
                                       c3.1,2.6,6.2,4.2,9.5,4.8c3.1,0.6,6.3,0.5,9.3-0.5c3.3-1,6.2-2.9,8.6-5.5c2.3-2.5,3.8-5.5,4.6-9c0.1-0.4,0.1-0.7,0.1-0.9
                                       c0.5-2.9,0.3-5.7-0.6-8.4c-0.9-2.9-2.4-5.5-4.5-7.7c-0.1-0.1-1.1-1-1.8-1.6c-0.5-0.4-0.9-0.8-0.9-0.8l-0.4-0.4l0.5-0.3
                                       c2.2-1.2,3.7-2.6,5.5-4.1l0.4-0.4c4.1-3.6,7.1-8,9.1-13.1c0.9-2.4,1.6-4.8,2-7.3c0.5-3,0.5-6.1,0.2-9.1c-0.3-2.3-0.6-4.3-1.2-6
                                       c-0.6-1.8-1.3-3.5-1.9-5.1c-0.2-0.6-0.6-1.3-1-2C97,9.1,80.5,8,77.3,7.9c-1.2-0.1-2.3-0.2-3.4-0.2c-12.4,0-23.2,0-33.9,0
                                       c-1,0-2.1,0.1-3.3,0.3c-3.3,0.6-6.2,1.9-8.8,4.1c-3.6,3-5.8,6.8-6.6,11.3c-0.5,2.5-0.4,5,0.1,7.5c0.5,2.5,1.6,4.9,3.1,7
                                       c1.8,2.5,4,4.4,6.6,5.8l0.5,0.3l-0.5,0.4c-3,2.7-4.9,6-5.8,9.9c0,0.1,0,0.1,0,0.2l0,0.1l-9.6,32.5c-0.9,3.2,0.1,6.7,3.1,9.9
                                       c2.8,3,6.9,5.5,11.6,6.9c4.9,1.5,9.9,1.6,13.9,0.5c4-1.1,6.6-3.5,7.6-6.5L53.7,91.3z M81.3,105.6c-1.1,0-2.1-0.1-3.2-0.3
                                       c-2.8-0.5-5.4-1.9-8.2-4.2l-0.6-0.5c-3.3-2.8-8.3-6.9-11.3-9.4c-1.4-1.1-2.8-2.3-4.2-3.4l-1.7-1.4L49,97
                                       c-1.4,4.7-9.6,6.6-17.9,4.1c-4.2-1.2-7.8-3.4-10.2-6c-2.2-2.4-3.1-5-2.5-7.2l1.3-4.4l8.4-28.3l0-0.2c1-4.1,3.3-6.7,6.5-9.9
                                       c0.7-0.7,1.8-1.5,2.5-2.1L35,42.2c-3.4-1.2-6.1-3.2-8.1-6c-1.3-1.8-2.1-3.7-2.6-5.9c-0.4-2.1-0.5-4.2-0.1-6.3
                                       c0.7-3.9,2.6-7,5.6-9.6c2.2-1.8,4.7-3,7.4-3.4c1-0.2,1.9-0.3,2.8-0.3c10.9,0,21.6,0,33.9,0c1,0,2.1,0.1,3.2,0.1l0.1,0
                                       c2.9,0,18,1,25.7,16.4l0.1,0.1c0.3,0.5,0.6,1.1,0.8,1.6c0.6,1.6,1.3,3.2,1.9,4.9c0.5,1.5,0.9,3.3,1.1,5.4
                                       c0.3,2.8,0.3,5.7-0.2,8.4c-0.3,2.3-1,4.5-1.8,6.7c-1.8,4.7-4.6,8.7-8.4,12c-2.3,2-4.9,3.7-7.9,5.1c-0.1,0.1-0.2,0.1-0.4,0.2
                                       l-1.4,1l1.8,1.4c0,0,2.8,2.4,3.7,3.3c0.2,0.2,0.5,0.5,0.8,0.8c1.8,1.9,3.1,4.1,3.8,6.6c0.8,2.3,0.9,4.7,0.5,7.1
                                       c0,0.2-0.1,0.5-0.1,0.7c-0.7,3-1.9,5.5-3.9,7.6c-2,2.2-4.4,3.8-7.3,4.6C84.5,105.3,82.9,105.6,81.3,105.6z M51.8,84.9l2.7,2.2
                                       c1.4,1.1,2.8,2.3,4.2,3.4c3,2.5,8,6.7,11.3,9.4l0.6,0.5c2.6,2.2,5.1,3.5,7.8,4c2.6,0.5,5.1,0.4,7.5-0.4c2.7-0.8,5-2.3,6.9-4.4
                                       c1.9-2,3.1-4.4,3.7-7.3c0-0.2,0.1-0.4,0.1-0.7c0.4-2.4,0.3-4.6-0.5-6.8c-0.7-2.4-1.9-4.5-3.6-6.2c-0.3-0.3-0.5-0.5-0.8-0.7
                                       c-0.9-0.9-3.6-3.2-3.7-3.2l-2.7-2.1l2.4-1.7c0.2-0.1,0.4-0.2,0.5-0.3c2.9-1.4,5.5-3,7.7-5c3.6-3.2,6.4-7.2,8.1-11.7
                                       c0.8-2.1,1.4-4.3,1.8-6.5c0.4-2.7,0.5-5.4,0.2-8.2c-0.2-2.1-0.6-3.7-1.1-5.2c-0.6-1.7-1.2-3.3-1.8-4.9c-0.2-0.5-0.4-1-0.7-1.4
                                       l-0.1-0.2c-7.5-14.9-22.1-15.9-25-15.9l-0.2,0c-1.1-0.1-2.1-0.1-3.1-0.1c-12.3,0-23,0-33.9,0c-0.8,0-1.7,0.1-2.6,0.2
                                       c-2.6,0.4-5,1.5-7,3.3c-2.9,2.5-4.7,5.4-5.3,9.1c-0.4,2-0.3,4,0.1,6c0.4,2,1.3,3.9,2.5,5.6c2,2.7,4.5,4.6,7.7,5.7l3.8,1.3
                                       l-0.7,0.5c0,0-2,1.4-3.1,2.4c-3.1,3.1-5.4,5.6-6.3,9.5l0,0.3l-8.4,28.3l-1.3,4.4c-0.6,1.9,0.2,4.1,2.3,6.4
                                       c2.3,2.5,5.8,4.6,9.9,5.8c7.8,2.3,15.5,0.7,16.8-3.5L51.8,84.9z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M158.1,75.9V36.4c0-2.1,0.6-3.7,1.7-4.9c1.1-1.2,2.6-1.7,4.6-1.7h15.5c6.8,0,12,1.4,15.5,4.2
                       c3.5,2.8,5.2,6.8,5.2,12.2c0,4.2-1.2,7.7-3.5,10.4s-5.7,4.5-10.1,5.2l13.6,16.3c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.6-0.5,1.1
                       c-0.4,0.7-1.3,1.4-2.4,2c-1.2,0.6-2.5,1-4.1,1c-1.7,0-3.1-0.4-4.2-1.1c-1.1-0.7-2.4-2-3.6-3.7l-11.1-13.9h-4.5V77
                       c0,3.6-1.8,5.4-5.3,5.4h-1.7C159.8,82.4,158.1,80.2,158.1,75.9z M170.1,54.8h9.1c3.1,0,5.5-0.7,7.1-2.1c1.6-1.4,2.4-3.3,2.4-5.8
                       c0-5.2-3.2-7.8-9.7-7.8h-8.9V54.8z"
                    />
                    <path
                      class="st0"
                      d="M205.4,62.7c0-6,1.9-11,5.8-15c3.9-4,8.8-5.9,14.8-5.9c5.8,0,10.5,1.8,14.2,5.5c3.6,3.7,5.4,8.4,5.4,14.2v0.8
                       c-0.1,1.1-0.3,1.9-0.6,2.4c-0.4,0.5-1.1,0.8-2.1,0.8h-27.2c0.4,2.7,1.6,4.9,3.7,6.5c2.1,1.6,4.7,2.4,7.8,2.4
                       c5.2,0,8.9-1.8,11.2-5.5c0.1-0.1,0.5,0,1.4,0.2c0.9,0.2,1.8,0.7,2.7,1.5c0.9,0.8,1.4,1.8,1.4,3.1c0,2.4-1.5,4.7-4.6,6.6
                       c-3.1,2-7.2,3-12.4,3c-6.5,0-11.7-1.9-15.5-5.6C207.3,73.8,205.4,68.9,205.4,62.7z M215.7,58.8h19.4c-0.1-2.7-1-4.9-2.6-6.6
                       c-1.6-1.7-3.8-2.5-6.6-2.5c-2.6,0-4.9,0.8-6.7,2.5C217.3,53.9,216.1,56.1,215.7,58.8z"
                    />
                    <path
                      class="st0"
                      d="M249.3,73.6c0-0.8,0.3-1.6,0.8-2.3c0.5-0.7,1.1-1.3,1.7-1.6c0.6-0.3,0.9-0.5,1-0.4c3.3,3.3,7.6,4.9,13,4.9
                       c4.6,0,6.9-1.1,6.9-3.4c0-1.2-0.6-2-1.7-2.5c-1.1-0.5-3.1-1-5.9-1.5c-0.4-0.1-0.6-0.1-0.8-0.2c-1.4-0.3-2.4-0.6-3-0.7
                       c-0.7-0.2-1.7-0.4-3-0.9s-2.3-0.9-3.1-1.4c-0.8-0.5-1.5-1.2-2.4-1.9c-0.8-0.8-1.5-1.7-1.9-2.8c-0.4-1.1-0.6-2.3-0.6-3.7
                       c0-4,1.5-7.2,4.5-9.6s7-3.7,12-3.7c5.1,0,9.1,0.9,11.8,2.7c2.8,1.8,4.2,4,4.2,6.6c0,0.9-0.3,1.7-0.9,2.4c-0.6,0.7-1.2,1.3-1.8,1.7
                       c-0.6,0.4-0.9,0.5-1,0.4c-3-3.3-6.9-4.9-11.5-4.9c-4.5,0-6.7,1.2-6.7,3.5c0,1.1,0.5,1.8,1.5,2.3s2.8,0.9,5.4,1.5
                       c0.7,0.2,1.1,0.3,1.5,0.4c1.6,0.4,2.8,0.7,3.7,0.9c0.9,0.2,2.1,0.6,3.7,1.2c1.6,0.6,2.8,1.3,3.7,2c0.9,0.7,1.6,1.7,2.3,3
                       c0.7,1.3,1,2.7,1,4.4c0,4.2-1.5,7.4-4.6,9.8c-3.1,2.4-7.3,3.5-12.7,3.5c-5.5,0-9.7-0.9-12.7-2.7C250.8,78.5,249.3,76.2,249.3,73.6
                       z"
                    />
                    <path
                      class="st0"
                      d="M288.3,62.7c0-6,1.9-11,5.8-15c3.9-4,8.8-5.9,14.8-5.9c5.8,0,10.5,1.8,14.2,5.5c3.6,3.7,5.4,8.4,5.4,14.2v0.8
                       c-0.1,1.1-0.3,1.9-0.6,2.4c-0.4,0.5-1.1,0.8-2.1,0.8h-27.2c0.4,2.7,1.6,4.9,3.7,6.5c2.1,1.6,4.7,2.4,7.8,2.4
                       c5.2,0,8.9-1.8,11.2-5.5c0.1-0.1,0.5,0,1.4,0.2c0.9,0.2,1.8,0.7,2.7,1.5c0.9,0.8,1.4,1.8,1.4,3.1c0,2.4-1.5,4.7-4.6,6.6
                       c-3.1,2-7.2,3-12.4,3c-6.5,0-11.7-1.9-15.5-5.6C290.3,73.8,288.3,68.9,288.3,62.7z M298.7,58.8h19.4c-0.1-2.7-1-4.9-2.6-6.6
                       c-1.6-1.7-3.8-2.5-6.6-2.5c-2.6,0-4.9,0.8-6.7,2.5C300.2,53.9,299.1,56.1,298.7,58.8z"
                    />
                    <path
                      class="st0"
                      d="M335,77V43.5c0-0.1,0.2-0.3,0.6-0.5c0.4-0.2,0.9-0.4,1.6-0.6c0.7-0.2,1.4-0.3,2.1-0.3c4.1,0,6.4,2.2,7,6.5
                       c1.7-4.7,4.9-7,9.6-7c2.3,0,4,0.6,5.4,1.7c1.3,1.2,2,2.7,2,4.7c0,1.3-0.3,2.5-0.9,3.5c-0.6,1-1.2,1.7-1.7,2
                       c-0.6,0.4-0.9,0.5-1,0.4c-1.4-1.3-3.1-2-5.2-2c-5.3,0-8,4.3-8,12.9V77c0,3.6-1.7,5.4-5,5.4h-1.2C336.7,82.4,335,80.6,335,77z"
                    />
                    <path
                      class="st0"
                      d="M365.1,45.5c-0.1-0.2,0.1-0.6,0.6-1.1c0.5-0.5,1.3-1.1,2.4-1.5c1.1-0.5,2.1-0.7,3.1-0.7
                       c3.1,0,5.2,1.9,6.5,5.8l8,23.7L394,48c1.2-3.9,3.3-5.8,6.2-5.8c1.1,0,2.1,0.2,3.2,0.7c1,0.5,1.8,1,2.3,1.5
                       c0.5,0.5,0.8,0.9,0.7,1.1l-14.1,34.1c-0.8,2-2.9,3-6.4,3c-3.4,0-5.6-1-6.4-2.9L365.1,45.5z"
                    />
                    <path
                      class="st0"
                      d="M409.4,71.6c0-4.5,2.1-7.9,6.2-10c4.1-2.2,10.5-3.3,19.2-3.4h1.3c0-5-2.7-7.5-8.1-7.5c-4.6,0-7.8,1.9-9.8,5.8
                       c0,0.2-0.6,0.2-1.8,0.1c-1.2-0.1-2.4-0.5-3.7-1.3c-1.2-0.8-1.8-1.9-1.8-3.4c0-2.8,1.6-5.1,4.7-7.1s7.5-3,13.2-3
                       c12.5,0,18.8,5.4,18.8,16.3v23.4c0,0.3-0.5,0.5-1.4,0.8c-0.9,0.3-1.9,0.4-3,0.4c-3.9,0-6.1-2.1-6.8-6.2c-3,4.4-7.6,6.6-13.7,6.6
                       c-4.1,0-7.3-1.1-9.7-3.2C410.6,77.7,409.4,75,409.4,71.6z M420.3,70.8c0,1.4,0.6,2.6,1.7,3.5c1.1,0.9,2.6,1.3,4.5,1.3
                       c2.8,0,5.1-0.8,6.9-2.5c1.8-1.7,2.7-3.8,2.7-6.4v-2.3l-2.1,0.1c-4.6,0.1-8,0.7-10.3,1.6C421.4,67.1,420.3,68.7,420.3,70.8z
                        M425.1,37.4c-1.5,0-2.3-0.2-2.6-0.6c-0.2-0.4,0.1-1.3,0.9-2.7c0.8-1.5,2-3.1,3.6-4.9c1.6-1.8,3.2-3.3,4.8-4.3
                       c1.4-0.9,2.7-1.4,4-1.4c1.5,0,2.7,0.4,3.7,1.3c1,0.9,1.5,1.9,1.5,3.2c0,2.5-1.3,4.5-4,6.1c-2,1.2-4.1,2.1-6.4,2.6
                       C428.5,37.2,426.6,37.5,425.1,37.4z"
                    />
                    <path
                      class="st0"
                      d="M455.6,77V43.5c0-0.1,0.2-0.3,0.6-0.5c0.4-0.2,0.9-0.4,1.6-0.6c0.7-0.2,1.4-0.3,2.1-0.3
                       c3.9,0,6.2,2.1,6.9,6.3c3.1-4.5,7.5-6.8,13-6.8c4.9,0,8.6,1.5,11,4.5s3.6,7,3.6,11.8V77c0,3.6-1.7,5.4-5,5.4h-1.2
                       c-3.4,0-5.2-1.8-5.2-5.4V59.6c0-2.7-0.6-4.7-1.9-6.3c-1.3-1.5-3.1-2.3-5.4-2.3c-4,0-6.9,1.7-8.7,5.2V77c0,3.6-1.7,5.4-5,5.4h-1.2
                       C457.3,82.4,455.6,80.6,455.6,77z"
                    />
                    <path
                      class="st0"
                      d="M500.4,62.6c0-6.3,1.7-11.4,5.1-15.2c3.4-3.8,7.8-5.7,13.3-5.7c2.7,0,5.2,0.6,7.4,1.7c2.2,1.1,4,2.6,5.2,4.4
                       V27.4c0-0.2,0.2-0.3,0.6-0.5c0.4-0.2,0.9-0.4,1.6-0.5c0.7-0.2,1.4-0.2,2.1-0.2c4.8,0,7.1,3,7.1,9.1V77c0,3.6-1.7,5.4-5,5.4h-1.2
                       c-3.5,0-5.2-1.8-5.2-5.3v-1.3c-2.8,4.8-7.1,7.3-12.8,7.3c-5.4,0-9.8-1.9-13.2-5.7C502.1,73.6,500.4,68.7,500.4,62.6z M511.4,62.6
                       c0,3.4,0.9,6.3,2.8,8.4c1.9,2.2,4.3,3.2,7.3,3.2c2.7,0,5.1-0.9,7-2.6c1.9-1.8,2.9-4,2.9-6.7v-7.5c-1.8-4.2-5.1-6.3-9.8-6.3
                       c-3.1,0-5.6,1.1-7.5,3.2C512.3,56.3,511.4,59.1,511.4,62.6z"
                    />
                    <path
                      class="st0"
                      d="M549.3,62.4c0-6.1,2-11.1,5.9-14.9c3.9-3.8,9-5.8,15.3-5.8c6.2,0,11.2,1.9,15.1,5.8c3.9,3.9,5.8,8.9,5.8,15.1
                       s-1.9,11.1-5.8,14.9c-3.9,3.8-9,5.6-15.2,5.6c-6.3,0-11.3-1.9-15.2-5.7C551.2,73.6,549.3,68.6,549.3,62.4z M559.3,62.4
                       c0,3.5,1.1,6.3,3.2,8.5c2.1,2.2,4.7,3.3,7.9,3.3c3.2,0,5.8-1.1,7.9-3.3c2.1-2.2,3.1-5,3.1-8.4c0-3.5-1-6.3-3.1-8.6
                       c-2.1-2.2-4.7-3.4-7.9-3.4c-3.2,0-5.8,1.1-7.9,3.3C560.4,56.2,559.3,59,559.3,62.4z"
                    />
                    <path
                      class="st0"
                      d="M598,77V43.5c0-0.1,0.2-0.3,0.6-0.5c0.4-0.2,0.9-0.4,1.6-0.6c0.7-0.2,1.4-0.3,2.1-0.3c3.9,0,6.2,2.1,6.9,6.3
                       c3.1-4.5,7.5-6.8,13-6.8c4.9,0,8.6,1.5,11,4.5s3.6,7,3.6,11.8V77c0,3.6-1.7,5.4-5,5.4h-1.2c-3.4,0-5.2-1.8-5.2-5.4V59.6
                       c0-2.7-0.6-4.7-1.9-6.3c-1.3-1.5-3.1-2.3-5.4-2.3c-4,0-6.9,1.7-8.7,5.2V77c0,3.6-1.7,5.4-5,5.4h-1.2C599.7,82.4,598,80.6,598,77z"
                    />
                    <path
                      class="st0"
                      d="M642.7,62.4c0-6.1,2-11.1,5.9-14.9c3.9-3.8,9-5.8,15.3-5.8c6.2,0,11.2,1.9,15.1,5.8c3.9,3.9,5.8,8.9,5.8,15.1
                       s-1.9,11.1-5.8,14.9c-3.9,3.8-9,5.6-15.2,5.6c-6.3,0-11.3-1.9-15.2-5.7C644.7,73.6,642.7,68.6,642.7,62.4z M652.8,62.4
                       c0,3.5,1.1,6.3,3.2,8.5c2.1,2.2,4.7,3.3,7.9,3.3c3.2,0,5.8-1.1,7.9-3.3c2.1-2.2,3.1-5,3.1-8.4c0-3.5-1-6.3-3.1-8.6
                       c-2.1-2.2-4.7-3.4-7.9-3.4c-3.2,0-5.8,1.1-7.9,3.3C653.8,56.2,652.8,59,652.8,62.4z"
                    />
                    <path
                      class="st0"
                      d="M688.7,73.6c0-0.8,0.3-1.6,0.8-2.3c0.5-0.7,1.1-1.3,1.7-1.6c0.6-0.3,0.9-0.5,1-0.4c3.3,3.3,7.6,4.9,13,4.9
                       c4.6,0,6.9-1.1,6.9-3.4c0-1.2-0.6-2-1.7-2.5c-1.1-0.5-3.1-1-5.9-1.5c-0.4-0.1-0.6-0.1-0.8-0.2c-1.3-0.3-2.4-0.6-3-0.7
                       c-0.7-0.2-1.7-0.4-3-0.9c-1.3-0.4-2.3-0.9-3.1-1.4c-0.7-0.5-1.5-1.2-2.4-1.9c-0.8-0.8-1.5-1.7-1.9-2.8c-0.4-1.1-0.6-2.3-0.6-3.7
                       c0-4,1.5-7.2,4.5-9.6s7-3.7,12-3.7c5.1,0,9.1,0.9,11.8,2.7c2.8,1.8,4.2,4,4.2,6.6c0,0.9-0.3,1.7-0.9,2.4c-0.6,0.7-1.2,1.3-1.8,1.7
                       c-0.6,0.4-0.9,0.5-1,0.4c-3-3.3-6.9-4.9-11.5-4.9c-4.5,0-6.7,1.2-6.7,3.5c0,1.1,0.5,1.8,1.5,2.3c1,0.4,2.8,0.9,5.4,1.5
                       c0.7,0.2,1.1,0.3,1.5,0.4c1.6,0.4,2.8,0.7,3.7,0.9c0.9,0.2,2.1,0.6,3.7,1.2c1.6,0.6,2.8,1.3,3.7,2c0.9,0.7,1.6,1.7,2.3,3
                       c0.7,1.3,1,2.7,1,4.4c0,4.2-1.5,7.4-4.6,9.8c-3.1,2.4-7.3,3.5-12.7,3.5c-5.5,0-9.7-0.9-12.7-2.7C690.2,78.5,688.7,76.2,688.7,73.6
                       z"
                    />
                  </g>
                </g>
              </svg>
            </router-link>

            <sidepanel-search />

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div v-if="false" class="cart-icon hidden" id="cart_button">
                <img
                  :src="`${environment.imageKeyCDN}/assets/images/icons/cart-icon.svg`"
                  alt=""
                />
              </div>

              <geolocation :isMobile="isMobile" />

              <sidepanel-auth />
            </div>
          </div>

          <div
            v-if="isMobile && isSearch"
            class="search-mobile-container navbar col-xxl-8 container px-4 py-5"
          >
            <sidepanel-search :isMobile="true" />
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="nav-clear" :class="{ 'no-search': !isSearch }"></div>
</template>
<style>
.navbar .dropdown-toggle,
.navbar .dropdown-menu a {
  cursor: pointer;
}

#custom-svg path {
  fill: red;
  stroke: red;
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
  color: inherit;
  text-decoration: none;
  background-color: inherit;
}

.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: var(--sitecolor);
}

#custom-svg path {
  fill: unset;
  stroke: unset;
}

@media (min-width: 767px) {
  .navbar .dropdown-toggle:not(.nav-link)::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.5em;
    vertical-align: 0;
    border-bottom: 0.3em solid transparent;
    border-top: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
}

.nav-clear {
  width: 100%;
  height: 60px;
}

.menu-icon {
  margin-right: 1.5rem;
  display: flex;
}

.menu-icon > svg {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
}

.user-icon > svg {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
}

.cart-icon > svg {
  width: 1.5rem;
  height: 1.375rem;
  flex-shrink: 0;
}

.location-icon > svg {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
}

.location-icon > svg.poligon {
  width: 0.5rem;
  height: 0.375rem;
  flex-shrink: 0;
}

.location-icon {
  display: flex;
  width: 8.125rem;
  height: 1.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  flex-shrink: 0;
}

.user-icon,
.cart-icon,
.location-icon {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon {
  margin-left: 1rem !important;
}

.menu-icon > i {
  font-size: 2rem;
}

.user-icon > i,
.cart-icon > i,
.location-icon > i {
  font-size: 1.5rem;
}

a.navbar-brand {
  display: flex;
  width: 9.5rem;
  height: 1.375rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.location-icon > span {
  color: #82cde5;
  font-family: Poppins;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.02438rem;
  width: 6rem;
}

.location-icon > i.fa-caret-down {
  margin-left: 0.5rem;
  margin-top: -0.1rem;
  font-size: 16px;
}

.location-icon.active {
  color: #82cde5;
}

.menu-icon,
.user-icon,
.cart-icon,
.location-icon {
  cursor: pointer;
}

div#navbarSupportedContent {
  display: flex;
  justify-content: flex-end;
}

#new_header {
  height: 4.5rem;
  display: flex;
  align-items: center;
}

.menu-options-container {
  display: flex;
  flex-direction: column;
}

.sidepanel-menu-main-container {
  height: 100%;
  padding: 4rem 2rem 2rem 1rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

span.menu-option {
  color: #8f90a6;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.0225rem;
}

.menu-options-footer-image {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem !important;
}

.menu-options-footer-image > span {
  color: #3a3a3c;
  font-family: Poppins;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02813rem;
}

.menu-options-footer-image > span {
  font-weight: bold;
}

.menu-options-footer-image > img {
  width: 2rem;
}

.sidebar-menu-header {
  margin: 2rem 0.5rem 0 2rem !important;
}

.menu-options-section > a {
  margin: 0.5rem 0rem !important;
}

.menu-options-section {
  margin-bottom: 1.5rem !important;
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.menu-subopciones-container {
  margin-top: 0.5rem !important;
}

.menu-subopciones-container > a {
  color: #3a3a3c;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.02625rem;
  margin: 0.5rem 0rem !important;
}

.dropdown > ul > li > a {
  color: unset;
}

.dropdown {
  cursor: pointer;
}

ul,
ul ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown ul {
  display: none;
}

.dropdown ul.show {
  display: block;
}

.arrow {
  display: inline-block;
  margin-left: -10px !important;
  transition: transform 0.2s ease-in-out;
}

.dropdown.show .arrow {
  transform: rotate(0deg);
}

.menu-subopciones-container {
  display: flex;
  flex-direction: column;
}

ul.menu-subopciones-container > li {
  margin: 0.5rem 0 !important;
  padding: 0 !important;
}

ul.menu-subopciones-container {
  margin-top: 0.5rem !important;
}

ul.menu-subopciones-container > li > ul {
  margin-top: 0.5rem !important;
}

ul.menu-subopciones-container > li > ul > li {
  margin: 0.5rem 0rem 0.5rem 0.6rem !important;
}

svg#custom-svg path {
  fill: #f23d60;
}

svg#custom-svg {
  max-height: 72px;
}

.search-mobile-container.navbar {
  display: none;
}

.main-header-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-mobile-content {
  background-color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: inherit;
  padding: 1rem;
  border-radius: 5rem;
  box-shadow: 1px 2px 5px 0.5px rgb(0 0 0 / 10%);
}

.search-mobile-content > span {
  margin-right: auto;
  font-weight: bold;
  font-size: 14px;
}

.search-mobile-content > i {
  font-size: 18px;
}

.search-mobile-content > i.fa-location-dot {
  color: #0089ffba;
  margin-right: 0.8rem;
}

.search-mobile-content > i.fa-magnifying-glass {
  color: #ff9800bf;
}

.search-mobile-container.navbar > .search-mobile-content {
  width: 100%;
  padding: 1rem !important;
}

li.dropdown > span {
  padding: 0 !important;
  color: #3a3a3c;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.02625rem;
}

.rotate {
  animation: rotate 0.3s ease-in-out forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-back {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.navbar-search-button {
  width: calc(80% - 20rem);
  height: 1.38rem;
  border: 0.5px solid #c7c9d9;
  padding: 0.1rem 0.4rem;
  margin: 0 4rem;
  border-radius: 1.37rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-search-button > i {
  font-size: 0.8rem;
  padding-top: 0.1rem !important;
  margin: 0 0.2rem;
}

.navbar-search-button > svg {
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
  margin-right: 0.2rem;
}

.navbar-search-button > span {
  color: #6b7588;
  font-family: Poppins;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a#logo > img {
  width: 8rem;
  margin-left: -2rem !important;
}

a#logo > svg.sabores {
  display: none;
}

#header {
  padding: 30px 0;
  height: 92px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}

#header #logo {
  float: left;
}

#header #logo h1 {
  font-size: 36px;
  margin: -4px 0 0 0;
  padding: 0;
  line-height: 1;
  display: inline-block;

  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

#header.header-fixed {
  background: linear-gradient(45deg, #1de099, var(--sitecolor));
  padding: 20px 0;
  height: 72px;
  transition: all 0.5s;
}

@media (max-width: 576px) {
  svg#custom-svg path {
    fill: white;
    stroke: white;
  }

  svg#custom-svg {
    width: 12rem;
    height: auto;
  }

  #new_header {
    height: 7rem;
    background-color: #82cde5;
    border-bottom: unset;
  }

  #new_header.header-fixed {
    background-color: #82cde5;
  }

  nav.navbar.navbar-expand-lg.navbar-light {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-top: 2rem;
    padding: 0;
  }

  .main-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .search-mobile-container.navbar {
    display: flex;
    width: 100%;
    top: 1.5rem !important;
    padding: 0 !important;
  }

  .nav-clear {
    height: 8.5rem;
  }

  .user-icon > i,
  .cart-icon > i,
  .location-icon > i,
  .menu-icon > i {
    color: white;
  }

  .sidepanel-menu-main-container {
    padding: 2rem 2rem 2rem 2rem !important;
  }

  .navbar-search-button,
  div#location_button,
  div#cart_button {
    display: none;
  }

  div#menu_button > svg path,
  div.user_button > svg path {
    fill: white;
  }

  .menu-icon {
    margin-right: 1rem;
  }

  a.navbar-brand {
    width: 11rem;
  }

  a#logo > img {
    display: none;
  }

  a#logo > svg.sabores {
    display: block;
  }
}
</style>
