export function useUtil() {
  const transformNameIntoUrl = (name) => {
    if (!name) return "";
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    name = name.replace("'", "");
    return name.replace(/\s/g, "-").toLowerCase();
  };

  const transformPathToName = (path) => {
    if (!path) return "";
    const prepositions = ["del", "de", "la", "el"];
    return path
      .replace(/-/g, " ")
      .split(" ")
      .map((word) =>
        prepositions.includes(word)
          ? word
          : word.charAt(0).toLocaleUpperCase() + word.slice(1)
      )
      .join(" ");
  };

  const changeCanonicalUrl = () => {
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (!linkTag) return;
    linkTag.href = window.location.href;
  };

  const removeMetaSeo = () => {
    const head = document.getElementsByTagName("head")[0];
    const metas = [...head.getElementsByTagName("meta")];

    const existEndMeta = metas.find((meta) => meta.name === "endMeta");
    if (!existEndMeta) return;

    for (const meta of metas) {
      if (meta.name === "endMeta") {
        head.removeChild(meta);
        break;
      }
      head.removeChild(meta);
    }

    document
      .querySelectorAll('script[type="application/ld+json"]')
      .forEach((script) => {
        script.remove();
      });
  };

  const defaultViewIndexs = {
    location: {
      isShowMore: true,
      isLoading: false,
    },
    category: {
      isShowMore: true,
      isLoading: false,
    },
    cuisine: {
      isShowMore: true,
      isLoading: false,
    },
    "food-type": {
      isShowMore: true,
      isLoading: false,
    },
    amenity: {
      isShowMore: true,
      isLoading: false,
    },
  };
  return {
    transformNameIntoUrl,
    transformPathToName,
    changeCanonicalUrl,
    removeMetaSeo,
    defaultViewIndexs,
  };
}
