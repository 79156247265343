import GeolocationStore from "@/store/geolocation.store";
import { useUtil } from "./useUtil";

export function useGeolocation() {
  const changeGeolocation = (item, active) => {
    const indexCountry = GeolocationStore.getters.findIndexCountryById(
      item.countryId
    );
    const countryName =
      GeolocationStore.state.countriesJson[indexCountry]?.code;

    if (item.type === "country") {
      GeolocationStore.commit("setGeolocationData", {
        country_id: item.typeId,
        country: item.index,
        countryName,
      });
      return;
    }

    if (item.type === "state") {
      const indexState = GeolocationStore.getters.findIndexStateById(
        indexCountry,
        item.typeId
      );
      GeolocationStore.commit("setGeolocationData", {
        state_id: indexState >= 0 ? item.typeId : null,
        state: indexState >= 0 ? indexState : "_all",
        country_id: active.country_id,
        country: indexCountry,
        countryName,
        stateName: indexState >= 0 ? item.name : null,
      });
      return;
    }
    if (item.type === "city" || item.type === "colony") {
      const indexState = GeolocationStore.getters.findIndexStateById(
        indexCountry,
        item.stateId
      );
      const indexCityOrColony =
        GeolocationStore.getters.findIndexCityOrColonyById(
          indexCountry,
          indexState,
          item.typeId
        );

      GeolocationStore.commit("setGeolocationData", {
        city_id:
          indexCityOrColony >= 0 && item.type === "city" ? item.typeId : null,
        colony_id:
          indexCityOrColony >= 0 && item.type === "colony" ? item.typeId : null,
        cityAndColony: indexCityOrColony >= 0 ? indexCityOrColony : "_all",
        state_id: item.stateId,
        state: indexState,
        country_id: item.countryId,
        country: indexCountry,
        countryName,
        stateName:
          GeolocationStore.state.countriesJson[indexCountry]?.states[indexState]
            ?.title,
        cityOrColonyName: indexCityOrColony >= 0 ? item.name : null,
      });
    }
  };

  const generateGeolocationUrl = (geolocationData = null) => {
    geolocationData = geolocationData || GeolocationStore.state.dataCopy;

    const { transformNameIntoUrl } = useUtil();

    if (!geolocationData.countryName) return "/mx/";
    let path = `/${transformNameIntoUrl(geolocationData.countryName)}`;
    if (
      geolocationData.stateName &&
      geolocationData.stateName !== "_all" &&
      geolocationData.stateName !== "Todos"
    ) {
      path += `/${transformNameIntoUrl(geolocationData.stateName)}`;
    }

    if (
      geolocationData.cityOrColonyName &&
      geolocationData.cityOrColonyName !== "_all" &&
      geolocationData.cityOrColonyName !== "Todos"
    ) {
      path += `/${transformNameIntoUrl(geolocationData.cityOrColonyName)}`;
    }

    return `${path}/`;
  };

  const generateGeolocationUrlByCountry = () => {
    const geolocationData = GeolocationStore.state.dataCopy;
    const { transformNameIntoUrl } = useUtil();
    return `/${transformNameIntoUrl(geolocationData.countryName)}/`;
  };

  const saveGeolocation = (data, active, fn = () => {}) => {
    localStorage.setItem("geolocationData", JSON.stringify(data));
    localStorage.setItem("geolocationActive", JSON.stringify(active));
    GeolocationStore.commit("setDataCopy");
    GeolocationStore.commit("setActiveCopy");
    fn(true);
  };

  return {
    changeGeolocation,
    generateGeolocationUrl,
    saveGeolocation,
    generateGeolocationUrlByCountry,
  };
}
