import { createStore } from "vuex";
import { fetchGet } from "./helper";
import UtilStore from "./utils.store";

const HomeStore = createStore({
  state: {
    isLoading: true,
    data: {
      carousels: [],
      locations: [],
      services: [],
    },
  },
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setHome(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    async getHome({ commit }, params = {}) {
      commit("setIsLoading", true);
      const data = await fetchGet("/api/home", { mode: "web", ...params });
      commit("setHome", data);
      UtilStore.dispatch("setSeoAndSchema", {
        seo: data.seo,
        schema: data.schema,
      });
    },
  },
});

export default HomeStore;
