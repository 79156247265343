export const replaceCdnUrl = (url) => {
  // ESTE PATRÓN ES PARA EL CDN DE AWS
  const pattern =
    /https:\/\/[^/]*\.s3[^/]*\.amazonaws\.com|https:\/\/reservandonos-b2c-(production|staging)\.s3\.us-west-2\.amazonaws\.com/g;
  return url.replace(pattern, "");
};

export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
  return id;
};

export const importComponentAsync = async (path) => {
  try {
    const module = path;
    return module.default;
  } catch (error) {
    console.error("Failed to import component:", error);
    return null;
  }
};
