import { createStore } from "vuex";
import { getGeolocationActive, getGeolocationData } from "./helper";
import { useFetch } from "../composables/useFetch";

const GeolocationStore = createStore({
  state: {
    countriesJson: [],
    actives: getGeolocationActive(),
    activesCopy: getGeolocationActive(),
    data: getGeolocationData(),
    dataCopy: getGeolocationData(),
    isLoading: true,
  },
  mutations: {
    setCountriesJson(state, payload) {
      state.countriesJson = payload;
    },

    setGeolocationData(
      { actives, data },
      {
        country_id = 142,
        state_id = null,
        city_id = null,
        colony_id = null,
        state = "_all",
        country = 0,
        cityAndColony = "_all",
        latitude = null,
        longitude = null,
        countryName = "mx",
        stateName = "",
        cityOrColonyName = "",
      }
    ) {
      data.country_id = country_id;
      data.state_id = state_id;
      data.city_id = city_id;
      data.colony_id = colony_id;
      data.latitude = latitude;
      data.longitude = longitude;
      data.countryName = countryName;
      data.stateName = stateName;
      data.cityOrColonyName = cityOrColonyName;

      actives.state = state;
      actives.country = country;
      actives.cityAndColony = cityAndColony;
    },

    setActiveCopy(state) {
      state.activesCopy = { ...state.actives };
    },

    setDataCopy(state) {
      state.dataCopy = { ...state.data };
    },

    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async getCountriesJson({ commit }) {
      const data = require("@/assets/json/countries-reservandonos.json");
      commit("setCountriesJson", data);
    },

    async getGeolocationByIpOrLocation(_, { latitude, longitude }) {
      const { fetchGet } = useFetch();
      return await fetchGet("/api/tools/getGeolocationByIpOrLocation", {
        latitude,
        longitude,
      });
    },
  },
  getters: {
    searchResults: (state) => (query) => {
      query = query.toLowerCase();

      const results = state.countriesJson.reduce((acc, country) => {
        if (acc.length >= 5) {
          return acc;
        }

        const stateResults = country.states
          .filter((state) => state.title.toLowerCase().includes(query))
          .map((state) => ({
            type: "state",
            name: state.title,
            typeId: state.id,
            countryId: country.id,
            country,
            title: state.title,
          }));

        const cityOrColonyResults = country.states.reduce((acc, state) => {
          if (acc.length >= 5) {
            return acc;
          }

          const cityOrColonyMatches = state.citiesAndColonies
            .filter((cityOrColony) =>
              cityOrColony.title.toLowerCase().includes(query)
            )
            .map((cityOrColony) => ({
              type: cityOrColony.type,
              name: cityOrColony.title,
              title: cityOrColony.title,
              typeId: cityOrColony.id,
              country,
              state,
              stateId: state.id,
              countryId: country.id,
            }));

          return [...acc, ...cityOrColonyMatches];
        }, []);

        return [...acc, ...stateResults, ...cityOrColonyResults];
      }, []);

      return results.slice(0, 5);
    },

    findIndexCountryById: (state) => (id) => {
      return state.countriesJson.findIndex((country) => country.id === id);
    },

    findIndexStateById: (state) => (countryIndex, id) => {
      return state.countriesJson[countryIndex].states.findIndex(
        (state) => state.id === id
      );
    },

    findIndexCityOrColonyById: (state) => (countryIndex, stateIndex, id) => {
      return state.countriesJson[countryIndex].states[
        stateIndex
      ].citiesAndColonies.findIndex((cityOrColony) => cityOrColony.id === id);
    },
  },
});

export default GeolocationStore;
