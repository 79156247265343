import { createStore } from "vuex";
import { fetchAll, fetchFormData, fetchSystem, getAuthUser } from "./helper";

const AuthStore = createStore({
  state: {
    user: getAuthUser(),
    token: getAuthUser(false),
    isPanelOpen: false,
    isActiveSignIn: true,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setIsPanelOpen(state, payload) {
      state.isPanelOpen = payload;
      document.body.style.overflow = payload === true ? "hidden" : "auto";
    },
    setIsActiveSignIn(state, payload) {
      state.isActiveSignIn = payload;
    },
  },
  actions: {
    async signIn({ commit }, params = {}) {
      const data = await fetchSystem({
        url: "/registeredCustomer/login",
        params,
      });
      commit("setUser", data.user);
      commit("setToken", data.token);
      localStorage.setItem("rs_s_t", JSON.stringify(data.token));
      localStorage.setItem("user", JSON.stringify(data.user));
    },

    async signUp(_, params = {}) {
      await fetchSystem({
        url: "/registeredCustomer/register",
        params,
      });
    },

    async saveToken({ state }, params = {}) {
      await fetchAll({
        url: "/api/auth/login",
        params: { ...params, token: state.token },
      });
    },

    async logout({ commit }) {
      commit("setUser", null);
      commit("setToken", null);
      localStorage.removeItem("rs_s_t");
      localStorage.removeItem("user");
    },

    async sendCodeForgotPassword(_, email) {
      await fetchSystem({
        url: `/registeredCustomer/resetPasswordEmail/${email}`,
        method: "GET",
      });
    },

    async resetPassword(_, params = {}) {
      const data = await fetchSystem({
        url: "/registeredCustomer/resetPassword",
        params,
      });

      if (!data.success) {
        throw new Error(data.message);
      }
      return data;
    },

    async signInGo(_, params = {}) {
      await fetchFormData({
        url: "/user-login",
        params: { isApi: true, ...params },
      });
    },
  },
});

export default AuthStore;
