import { createApp } from "vue";
import Vuex from "vuex";
import App from "./layouts/base.vue";
import router from "./routes/main.routes";
import { createPinia } from "pinia";
import "es6-promise/auto";
import "@dmuy/timepicker/dist/mdtimepicker.css";
import "bootstrap/dist/css/bootstrap.css"; // Importa los estilos CSS de Bootstrap
import "bootstrap"; // Importa el archivo JavaScript de Bootstrap
import "./../public/assets/css/app.css";
import vueTimePicker from "@dmuy/timepicker/vue3-timepicker";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import skeleton from "./components/tools/skeleton.vue";
import imgComponent from "./components/tools/img-component.vue";
import jQuery from "jquery";

const pinia = createPinia();

const app = createApp(App);
app.config.devtools = true;
window.$ = window.jQuery = jQuery;
app.use(pinia);
app.use(router);
app.use(Vuex);
app.use(vueTimePicker);
app.component("skeleton", skeleton);
app.component("img-component", imgComponent);
app.use(VueGoogleMaps, {
  load: {
    libraries: "places",
    language: "es",
  },
});
app.mount("#app");
