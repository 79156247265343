import { createStore } from "vuex";

const UtilStore = createStore({
  state: {
    isMobile: window.innerWidth < 768,
    isSmallScreen: window.innerWidth < 400,
    isSearch: true,
    colorHeader: "#82cde5",
    title: "",
    countries: [],
    hash: "",
    isVisibleHeader: true,
    isVisibleFooter: true,
    previousRoute: {
      name: "",
      path: "",
    },
    isContentSearch: true,
    textGoogle: "",
    isOpenSearchPanel: false,
    menuCountryId: null,
  },
  mutations: {
    setIsMobile(state) {
      state.isMobile = window.innerWidth < 768;
    },
    setIsSmallScreen(state) {
      state.isSmallScreen = window.innerWidth < 400;
    },
    setColorHeader(state, payload) {
      state.colorHeader = `#${payload}`;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setIsSearch(state, payload) {
      state.isSearch = payload;
    },
    setHash(state, payload) {
      state.hash = payload;
    },
    setIsVisibleHeader(state, payload) {
      state.isVisibleHeader = payload;
    },
    setPreviousRoute(state, payload) {
      state.previousRoute = payload;
    },
    setIsContentSearch(state, payload) {
      state.isContentSearch = payload;
    },
    setTextGoogle(state, payload) {
      state.textGoogle = payload;
    },
    setIsVisibleFooter(state, payload) {
      state.isVisibleFooter = payload;
    },
    setIsOpenSearchPanel(state, payload) {
      state.isOpenSearchPanel = payload;
    },
    setMenuCountryId(state, payload) {
      state.menuCountryId = payload;
    },
  },
  actions: {
    async getCountries({ commit }) {
      const data = require("@/assets/json/countries.json");
      commit("setCountries", data);
    },

    async setHreflang() {
      document.documentElement.lang = `es-MX`;
      const url = window.location.href;

      const head = document.getElementsByTagName("head")[0];
      const link = document.createElement("link");
      link.setAttribute("rel", "alternate");
      link.setAttribute("hreflang", `es-mx`);
      link.setAttribute("href", url);
      head.insertBefore(link, head.firstChild);

      const meta = document.createElement("meta");
      meta.setAttribute("name", "dcterms.language");
      meta.setAttribute("content", `es-MX`);
      head.insertBefore(meta, head.firstChild);
    },

    async setSeoAndSchema(_, { seo, schema }) {
      const head = document.getElementsByTagName("head")[0];

      if (seo) {
        const meta = document.createElement("meta");
        meta.setAttribute("name", "endMeta");
        meta.setAttribute("content", "endMeta");
        head.insertBefore(meta, head.firstChild);
      }

      /* SCHEMA */

      if (schema) {
        const script = document.createElement("script");
        script.setAttribute("type", "application/ld+json");
        script.innerHTML = JSON.stringify(schema);
        head.insertBefore(script, head.firstChild);
      }

      if (!seo) return;

      /* GENERAL NAMES */

      const generalNames = seo.general_names;

      Object.keys(generalNames).forEach((key) => {
        const meta = document.createElement("meta");
        meta.setAttribute("name", key);
        meta.setAttribute("content", generalNames[key]);
        head.insertBefore(meta, head.firstChild);
      });

      /* GENERAL PROPERTIES */

      const generalProperties = seo.general_properties;
      Object.keys(generalProperties).forEach((key) => {
        /* IMAGES */
        if (
          key === "image" &&
          generalProperties[key] !== "" &&
          generalProperties[key] !== null
        ) {
          const images = generalProperties[key];
          Object.keys(images).forEach((imageKey) => {
            const meta = document.createElement("meta");
            meta.setAttribute(
              "property",
              `${imageKey === "url" ? "og:image" : `og:image:${imageKey}`}`
            );
            meta.setAttribute("content", images[imageKey]);
            head.insertBefore(meta, head.firstChild);
          });
        } else {
          const meta = document.createElement("meta");
          meta.setAttribute("property", `og:${key}`);
          meta.setAttribute("content", generalProperties[key]);
          head.insertBefore(meta, head.firstChild);
        }
      });

      /* REDES SOCIALES */

      const socialNetworks = seo.socials;
      Object.keys(socialNetworks).forEach((socialKey) => {
        Object.keys(socialNetworks[socialKey]).forEach((key) => {
          if (socialKey === "twitter") {
            const meta = document.createElement("meta");
            meta.setAttribute("name", `twitter:${key}`);
            meta.setAttribute("content", socialNetworks[socialKey][key]);
            head.insertBefore(meta, head.firstChild);
            return;
          }

          if (socialKey === "facebook") {
            const meta = document.createElement("meta");
            meta.setAttribute("property", `facebook:${key}`);
            meta.setAttribute("content", socialNetworks[socialKey][key]);
            head.insertBefore(meta, head.firstChild);
            return;
          }
        });
      });
    },
  },

  getters: {
    activeCountry: (state) => (countryCode) =>
      state.countries.find((country) => country.dial_code === countryCode),
  },
});

export default UtilStore;
