<script setup>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import UtilStore from "@/store/utils.store";
import { environment } from "../env/environment";
import { ImageEngineProvider } from "@imageengine/vue3";
import { useRouter } from "vue-router";
import HeaderGlobal from "./header/header.vue";
import FooterReservandonos from "./footer.vue";
import { confirmAlert, informationAlert } from "@/utils/alert";
import { useIdle } from "@vueuse/core";
import AuthStore from "@/store/auth.store";
import Swal from "sweetalert2";

const title = computed(() => UtilStore.state.title);
const router = useRouter();
const isReadyComponent = ref(false);
const isVisibleHeader = computed(() => UtilStore.state.isVisibleHeader);
const isVisibleFooter = computed(() => UtilStore.state.isVisibleFooter);

onMounted(() => {
  router.afterEach(() => {
    isReadyComponent.value = true;
  });
  document.title = "Reservándonos";
  UtilStore.commit("setHash", new Date().getTime());
});

watch(title, (newValue) => {
  document.title = newValue;
});

const updateIsMobile = () => {
  UtilStore.commit("setIsMobile");
  UtilStore.commit("setIsSmallScreen");
};

onMounted(() => {
  window.addEventListener("resize", updateIsMobile);
});

onBeforeMount(() => {
  window.removeEventListener("resize", updateIsMobile);
});

const isIdle = useIdle(300000);

const logout = () => {
  Swal.close();
  AuthStore.dispatch("logout");
  AuthStore.commit("setIsPanelOpen", true);
  informationAlert("Tu sesión ha expirado por inactividad");
};

// OBSERVAMOS LOS CAMBIOS DE LA VARIABLE isIdle.idle (true si el usuario está inactivo)
watch(isIdle.idle, async (newValue) => {
  console.log("isIdle.idle", AuthStore.state.token);
  if (newValue && AuthStore.state.token) {
    const responsePromise = confirmAlert({
      text: "Parece que has estado inactivo por un tiempo. ¿Quieres seguir en la página?",
    });

    const autoLogoutTimer = setTimeout(() => {
      logout();
    }, 15000);

    const response = await responsePromise;

    // Si el usuario confirma que quiere continuar, cancela el temporizador y resetea el estado de inactividad
    if (response.isConfirmed) {
      clearTimeout(autoLogoutTimer);
      isIdle.reset();
    }

    if (response.isDenied) {
      clearTimeout(autoLogoutTimer);
      logout();
    }
  }
});
</script>

<template>
  <ImageEngineProvider :deliveryAddress="environment.imageKeyCDN">
    <Header-global v-if="isReadyComponent && isVisibleHeader" :title="title" />
    <router-view></router-view>
    <Footer-reservandonos v-if="isReadyComponent && isVisibleFooter" />
  </ImageEngineProvider>
</template>
<style lang="css">
.general-config {
  width: 100%;
  min-height: 70vh;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}
</style>
