import { computed, ref } from "vue";
import axios from "axios";

export function useStore(route = null) {
  const isLoading = ref(false);
  const slug = computed(() =>
    route.params.slug?.length > 0 ? route.params.slug : null
  );
  const productCategory = computed(() => route.params.category ?? null);
  const recommendedNearYou = ref({
    page: 0,
    showMore: false,
    data: [],
  });
  const top = ref([]);
  const store = ref(null);
  const getRecommendedNearYou = (location, nextPage = false) => {
    let page = 1;
    if (nextPage === true) page = (recommendedNearYou.value.page ?? 1) + 1;
    isLoading.value = true;
    axios
      .get(`/api/store/recommended-near-you/${slug.value}`, {
        params: {
          page: page,
          slug: slug.value,
          location: location,
          productCategory: productCategory.value,
        },
      })
      .then((result) => {
        let data = result.data;
        if (data.page == 1) recommendedNearYou.value.data = data.data;
        else
          recommendedNearYou.value.data = [
            ...recommendedNearYou.value.data,
            ...data.data,
          ];

        recommendedNearYou.value.page = data.page;
        recommendedNearYou.value.showMore = data.showMore;
        isLoading.value = false;
      });
  };

  const getTop = (location) => {
    isLoading.value = true;
    axios
      .get(`/api/store/top/${slug.value}`, {
        params: {
          slug: slug.value,
          location: location,
          productCategory: productCategory.value,
        },
      })
      .then((result) => {
        top.value = result.data.data;
        isLoading.value = false;
      });
  };

  const getStore = async (slug_) => {
    isLoading.value = true;
    let bySlug = slug_ ?? slug.value;
    let { data: response } = await axios.get(`/api/store/${bySlug}/detail`);
    store.value = response.data;
    isLoading.value = false;
    return store.value;
  };
  return {
    getRecommendedNearYou,
    recommendedNearYou,
    top,
    getTop,
    getStore,
    store,
    isLoading,
  };
}
