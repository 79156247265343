export default [
  {
    path: "",
    name: "redirect",
    redirect: { name: "home" },
  },
  {
    path: "/invoice",
    name: "login",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../components/checkout-services/invoice.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/home/home.vue"),
  },
  {
    path: "/encuesta-satisfaccion",
    name: "satisfaction-survey",
    component: () =>
      import("../pages/satisfaction-survey/satisfaction-survey.vue"),
  },
  {
    path: "/experiencias",
    name: "experiences",
    component: () => import("../pages/experiences/experiences.vue"),
  },
  {
    path: "/experiencia/:slug",
    name: "experience",
    props: true,
    component: () => import("../pages/experiences/profile.vue"),
  },
  {
    path: "/experiencia/:slug/comprar",
    name: "experience-checkout",
    props: true,
    component: () => import("../pages/experiences/checkout.vue"),
  },
  {
    path: "/terminos-y-condiciones",
    name: "terms-and-conditions",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/information/terms-and-conditions.vue"),
  },
  {
    path: "/aviso-de-privacidad",
    name: "privacy",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/information/privacy.vue"),
  },
  {
    path: "/comunicate-con-nuestro-equipo-de-concierge",
    name: "concierge",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/information/concierge.vue"),
  },
  {
    path: "/panel-de-usuario",
    name: "panel-de-usuario",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../pages/user-panel/user-panel.vue"),
  },
  {
    path: "/olvide-mi-contrasena",
    name: "forgot-password",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/forgot-password/forgot-password.vue"),
  },
  {
    path: "/cadenas-de-restaurantes-bares-y-antros",
    name: "chains",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/chains/chain.vue"),
  },
  {
    path: "/lugar/:slug/reservar",
    name: "reservation",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/profile-site/reservation.vue"),
  },
  {
    path: "/lugar/:slug/menu",
    name: "menu",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/profile-site/menu.vue"),
  },
  {
    path: "/lugar/:slug",
    name: "profile-site",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/profile-site/profile-site.vue"),
  },
  {
    path: "/order/:code",
    name: "order",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/orders/index.vue"),
  },
  {
    path: "/stores/:slug?/:category?",
    name: "stores",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/food-and-delivery/index.vue"),
  },
  {
    path: "/store/:slug",
    name: "store-details",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/food-and-delivery/detail.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../pages/food-and-delivery/checkout.vue"),
  },
  /* {
        path: '/:slug',
        name: 'slug',
        props: true,
        meta: {
            requiresAuth: false
        },
        component: () => import('../pages/slug/index.vue'),
    },
    {
        path: '/:slug/:slug2',
        name: 'slug-2',
        props: true,
        meta: {
            requiresAuth: false
        },
        component: () => import('../pages/slug/index.vue'),
    },
    {
        path: '/:slug/:slug2/:slug3',
        name: 'slug-3',
        props: true,
        meta: {
            requiresAuth: false
        },
        component: () => import('../pages/slug/index.vue'),
    }, */
  { path: "/:pathMatch(.*)*", name: "redi", redirect: "/not-found" },
];
