const env = process.env;
export const environment = {
  url: env.VUE_APP_URL,
  system: env.VUE_APP_SYSTEM,
  version: 1,
  imageKeyBack: env.VUE_APP_IMAGE_KEY_BACK,
  imageKeyCDN: env.VUE_APP_IMAGE_KEY_CDN,
  awsUrl: env.VUE_APP_AWS,
  stripeKey:
    "pk_test_51LhdkHAhW95WbxnIEOLVxYxpbDhfadyjudk3YWfbNg8nJjuM0ZbBZdCo9Ym15VEVt5lxAWh7FjEA5yHV5Ny4xvRj00Kekef5Xk",
};
