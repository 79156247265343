<template>
  <div :class="{ 'w-100 h-100': isFatherFullWidthAndHeight }">
    <ImageComponent
      v-if="!isErrorLogo"
      v-bind="$attrs"
      :style="{ visibility: isLoadingImg ? 'hidden' : 'visible' }"
      class="user-select-none"
      :class="{ isLoadingImg: isLoadingImg && showSkeleton }"
      :src="
        replaceCdnUrl(
          src ? src : `/assets/images/website-logo-icon/isotipo.svg`
        )
      "
      :directives="{
        outputFormat: 'webp',
        ...directives,
      }"
      :attributes="{ alt }"
      :title="title"
      :width="`${width}px`"
      :height="`${height}px`"
      :loading="!isEager ? 'lazy' : 'eager'"
      fetchpriority="high"
      @load="isLoadingImg = false"
      @error="isErrorLogo = true"
    />
    <skeleton
      v-if="isLoadingImg && !isErrorLogo && showSkeleton"
      :isImg="isImg && !isCircle"
      :isCircle="isCircle"
      v-bind="$attrs"
      :width="`${width}px`"
      :height="`${height}px`"
    />
    <img
      v-if="isErrorLogo"
      loading="lazy"
      :src="`${environment.imageKeyCDN}/assets/images/website-logo-icon/isotipo.svg`"
      alt="profile"
      :width="width"
      :height="height"
    />
  </div>
</template>
<script>
import { Image } from "@imageengine/vue3";

import { defineComponent, ref, watch } from "vue";
import { replaceCdnUrl } from "../../utils/helper";
import { environment } from "../../env/environment";

export default defineComponent({
  inheritAttrs: false,
  props: {
    src: {
      type: [String, null, undefined],
      required: true,
    },
    directives: {
      type: Object,
      default: () => ({}),
    },
    alt: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: " ",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    isEager: {
      type: Boolean,
      default: false,
    },
    isImg: {
      type: Boolean,
      default: true,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    isFatherFullWidthAndHeight: {
      type: Boolean,
      default: false,
    },
    showSkeleton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ImageComponent: Image,
  },
  setup(props) {
    const isLoadingImg = ref(true);
    const isErrorLogo = ref(false);

    watch(
      () => props.src,
      () => {
        isErrorLogo.value = false;
      }
    );

    return {
      environment,
      isLoadingImg,
      isErrorLogo,
      replaceCdnUrl,
    };
  },
});
</script>

<style>
.isLoadingImg {
  width: 0 !important;
  height: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
}
</style>
